<template>
  <!-- 路由 -->
  <router-view></router-view>
</template>

<style>
html,
body,
#app {
    margin: 0px;
    padding: 0px;
    height: 100%;
    font-family: "Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif;
}
  /* 滚动条样式 */
  ::-webkit-scrollbar {
    width:5px;
    height:5px;
  }

  /*滚动条里面小方块*/
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #d6d6d6;
  }


  /* 弹框居中 */
  .el-dialog{
    display: flex;
    flex-direction: column;
    margin:0 !important;
    position:absolute;
    top:50%;
    left:50%;
    transform:translate(-50%,-50%);
    max-height:calc(100% - 30px);
    max-width:calc(100% - 30px);
  }
  .el-dialog .el-dialog__body{
    flex:1;
    overflow: auto;
    padding: 0px 20px;
  }
</style>
