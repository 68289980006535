import axios from 'axios'
import router from '../router'
//请求地址
var ServerUrl = files_config.ServerUrl
axios.defaults.baseURL = ServerUrl
axios.defaults.withCredentials = true

// 请求拦截器
axios.interceptors.request.use(
    function (config) {
        //添加Token
        var token = window.sessionStorage.getItem("token");
        if (token) {
            //添加token
            config.headers.token = token;
        }
        return config;
    },
    function (error) {
        console.log('请求错误', error)
        return Promise.reject(error)
    }
)

// 响应拦截器
axios.interceptors.response.use(
    function (response){
        if(response.data.code==403){
            //身份过期
            window.sessionStorage.clear();
            router.push("/");
        }
        return response
    },
    function (error) {
        console.log('响应错误', error)
        return Promise.reject(error)
    }
)


//get请求
export const get = (url, params) => {
    return axios.get(url, params)
}
// post请求
export const post = (url, params) => {
    return axios.post(url, params)
}