import { createRouter, createWebHashHistory } from 'vue-router'
const Index = () => import('../components/Index.vue')
const Login = () => import('../views/Login.vue')
const AdminDataAnalysis = () => import('../views/AdminDataAnalysis.vue')
const Controller = () => import('../views/Controller.vue')
const AdminController = () => import('../views/AdminController.vue')
const UserLog = () => import('../views/UserLog.vue')
const ControllerLog = () => import('../views/ControllerLog.vue')
const User = () => import('../views/User.vue')
const AdminUser = () => import('../views/AdminUser.vue')

const routes = [
  {path: '/',name: 'Login',component: Login},
  {path: '/Index',name: 'Index',component: Index,children:[
	{path: '/AdminDataAnalysis',name: 'AdminDataAnalysis',component: AdminDataAnalysis},
	{path: '/Controller',name: 'Controller',component: Controller},
	{path: '/AdminController',name: 'AdminController',component: AdminController},
	{path: '/UserLog',name: 'UserLog',component: UserLog},
	{path: '/ControllerLog',name: 'ControllerLog',component: ControllerLog},
	{path: '/User',name: 'User',component: User},
	{path: '/AdminUser',name: 'AdminUser',component: AdminUser},
  ]}
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach((to,from,next)=>{
	if(to.path === '/') return next()
	//获取保存的信息
	const tokenStr = window.sessionStorage.getItem('token')
	if(!tokenStr) return next(Login)
	next()
})

export default router
