import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import ElementPlus from 'element-plus'
import locale from "element-plus/lib/locale/lang/zh-cn";
import 'element-plus/dist/index.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import {post,get} from './config/Request'

const app = createApp(App)
//封装的请求
app.config.globalProperties.$post = post
app.config.globalProperties.$get = get


//element图标注册
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}

app.use(router)
app.use(ElementPlus, {locale})
app.mount('#app')
